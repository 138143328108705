/* src/App.css */

.container {
  text-align: center;
  margin-top: 50px;
}

.input {
  padding: 10px;
  width: 300px;
  margin-bottom: 20px;
}

.download-button {
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}
